import React, { Fragment } from "react";

import { Meta } from "./contact/meta";
import { Form } from "./contact/form";

export const Template = () => (
  <Fragment>
    <Meta />
    <Form />
  </Fragment>
);

export default Template;
