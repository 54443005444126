import React, { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import {
  Section,
  Container,
  Title,
  Text,
  Textarea,
  Input,
  Button,
  Modal,
  Markdown,
  getProperty,
  isValidEmail,
} from "@lachevaliniere/shared";

import { useStaticContact } from "hooks";

import styles from "./form.module.scss";

const STATUS_IDLE = "idle";
const STATUS_BUSY = "busy";
const STATUS_SENT = "sent";
const STATUS_FAIL = "fail";

//
// Uses recaptcha v3 before sending to cloud function:
// @see https://developers.google.com/recaptcha/docs/v3
//
const GOOGLE_CLOUD_FUNCTION_URL =
  "https://europe-west3-lachevaliniere-frontend.cloudfunctions.net/send-mail";
const RECAPTCHA_SITE_KEY = "6LcDHy4aAAAAALwHxr94--1t-Jqh9vOvSN8Ia4BW";

export const Form = ({ subject = "Contact form" }) => {
  const [state, setState] = useState({});
  const [status, setStatus] = useState(STATUS_IDLE);

  const staticContact = useStaticContact();

  const title = getProperty(staticContact, "data.metaTitle");
  const fields = getProperty(staticContact, "data.formFields");
  const submit = getProperty(staticContact, "data.formSubmit");
  const hint = getProperty(staticContact, "data.formHint");
  const recaptcha = getProperty(staticContact, "data.formRecaptcha");
  const busyTitle = getProperty(staticContact, "data.formBusyTitle");
  const busyMessage = getProperty(staticContact, "data.formBusyMessage");
  const successTitle = getProperty(staticContact, "data.formSuccessTitle");
  const successMessage = getProperty(staticContact, "data.formSuccessMessage");
  const failureTitle = getProperty(staticContact, "data.formFailureTitle");
  const failureMessage = getProperty(staticContact, "data.formFailureMessage");

  const [modalTitle, setModalTitle] = useState(successTitle);
  const [modalMessage, setModalMessage] = useState(successMessage);

  const changeHandler = useCallback((field, value) => {
    setState((currentState) => ({
      ...currentState,
      ...{
        [getProperty(field, "alias")]: value,
      },
    }));
  }, []);

  const submitHandler = useCallback(() => {
    const submitFormData = async () => {
      setModalMessage(busyMessage);
      setModalTitle(busyTitle);
      setStatus(STATUS_BUSY);

      try {
        const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: "submit" });
        const response = await fetch(GOOGLE_CLOUD_FUNCTION_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...state,
            ...{
              token,
            },
          }),
        });

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        setModalMessage(successMessage);
        setModalTitle(successTitle);
        setStatus(STATUS_SENT);
      } catch (e) {
        setModalMessage(failureMessage);
        setModalTitle(failureTitle);
        setStatus(STATUS_FAIL);
      }
    };

    window.grecaptcha.ready(submitFormData);
  }, [state, busyTitle, busyMessage, successTitle, successMessage, failureTitle, failureMessage]);

  const closeHandler = useCallback(() => {
    if (status === STATUS_SENT) {
      setState({});
    }

    setStatus(STATUS_IDLE);
    setModalMessage(null);
    setModalTitle(null);
  }, [status]);

  const isValid = useMemo(() => {
    for (let { alias, type, isRequired } of fields) {
      const value = state[alias];
      if (!isRequired) {
        continue;
      }

      switch (type) {
        case "email":
          if (!isValidEmail(value)) {
            return false;
          }

          break;
        default:
          if (!value) {
            return false;
          }
      }
    }

    return true;
  }, [fields, state]);

  const showModal = useMemo(() => {
    switch (status) {
      case STATUS_IDLE:
        return false;
      default:
        return true;
    }
  }, [status]);

  return (
    <Section color="tertiary">
      <Container>
        <Title wrapper="h1">{title}</Title>

        <div className={styles.fields}>
          {fields.map((field, index) => (
            <Field key={index} value={state[field.alias]} field={field} onChange={changeHandler} />
          ))}
        </div>
        <div className={styles.submit}>
          <Button type="special" disabled={!isValid} onClick={submitHandler}>
            {submit}
          </Button>
        </div>
        <div className={styles.hint}>
          <Text size="sm">{hint}</Text>
        </div>
        <Text size="xs">
          <Markdown content={recaptcha} />
        </Text>
      </Container>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}></script>
      </Helmet>
      {showModal && (
        <Modal
          header={
            <Title wrapper="h3" noMargin={true}>
              {modalTitle}
            </Title>
          }
          onClose={closeHandler}>
          {modalMessage}
        </Modal>
      )}
    </Section>
  );
};

const Field = ({ field, value, onChange }) => {
  const type = getProperty(field, "type");
  const name = getProperty(field, "name");
  const alias = getProperty(field, "alias");
  const isRequired = getProperty(field, "isRequired");
  const isHalfWidth = getProperty(field, "isHalfWidth");

  const changeHandler = useCallback(
    (value) => {
      onChange(field, value);
    },
    [field, onChange]
  );

  const wrapperClassNames = useMemo(
    () =>
      [styles.field, isRequired ? styles.required : "", isHalfWidth ? styles.halfWidth : ""].join(
        " "
      ),
    [isRequired, isHalfWidth]
  );

  const Type = useMemo(() => {
    switch (type) {
      case "textarea":
        return Textarea;
      default:
        return Input;
    }
  }, [type]);

  const placeholder = useMemo(() => (isRequired ? `${name} *` : name), [name, isRequired]);

  return (
    <div className={wrapperClassNames}>
      <Type id={alias} value={value} placeholder={placeholder} onChange={changeHandler} />
    </div>
  );
};
